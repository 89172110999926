<!--
 * @Description: 餐厅&商铺
 * @Author: 琢磨先生
 * @Date: 2022-10-21 00:44:46
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-21 00:44:49
-->
<template>
    <div>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>

</style>